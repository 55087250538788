export default [
    {
        label: 'Dashboard',
        link: '/dashboard',
    },
    {
        label: 'ToDo',
        link: '/todo',
    },
    {
        label: 'Schedule',
        link: '/schedule',
    },
    {
        label: 'Exams',
        link: '/exams',
    },
    {
        label: 'Subjects',
        link: '/subjects',
    },
];