export const USER_SET_SIGNED_IN = 'USER_SET_SIGNED_IN';
export const USER_SET_SIGNED_OUT = 'USER_SET_SIGNED_OUT';
export const USER_START_SIGN_OUT = 'USER_START_SIGN_OUT';
export const USER_SIGN_OUT_FAIL = 'USER_SIGN_OUT_FAIL';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const POST_USER_DATA = 'POST_USER_DATA';
export const ADD_USER_AND_DATA = 'ADD_USER_AND_DATA';

export const FETCH_SPACES = 'FETCH_SPACES';
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS';
export const FETCH_SPACES_FAIL = 'FETCH_SPACES_FAIL';
export const SET_SPACE = 'SET_SPACE';
export const ADD_SPACE_LOCALLY = 'ADD_SPACE_LOCALLY';
export const REMOVE_SPACE_LOCALLY = 'REMOVE_SPACE_LOCALLY';
export const ALTER_SPACE_LOCALLY = 'ALTER_SPACE_LOCALLY';

export const FETCH_SHALLOW_SUBJECTS = 'FETCH_SHALLOW_SUBJECTS';
export const FETCH_SHALLOW_SUBJECTS_SUCCESS = 'FETCH_SHALLOW_SUBJECTS_SUCCESS';
export const FETCH_SHALLOW_SUBJECTS_FAIL = 'FETCH_SHALLOW_SUBJECTS_FAIL';

export const REMOVE_SUBJECT_LOCALLY = 'REMOVE_SUBJECT_LOCALLY';
export const ADD_SUBJECT_LOCALLY = 'ADD_SUBJECT_LOCALLY';
export const UPDATE_SUBJECT_LOCALLY = 'UPDATE_SUBJECT_LOCALLY';

export const FETCH_TASKS = 'FETCH_TASKS';
export const REFRESH_TASKS = 'REFRESH_TASKS';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAIL = 'FETCH_TASKS_FAIL';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';
export const SET_TASKS_LOCALLY = 'SET_TASKS_LOCALLY';
export const CHECK_TASK = 'CHECK_TASK';
export const CHECK_TASK_WITHOUT_UPDATE = 'CHECK_TASK_WITHOUT_UPDATE';
export const UNCHECK_TASK = 'UNCHECK_TASK';
export const DATA_SET_ERROR = 'DATA_SET_ERROR';
export const ADD_AND_SAVE_NEW_TASK = 'ADD_AND_SAVE_NEW_TASK';
export const ADD_AND_SAVE_NEW_TASK_SUCCESS = 'ADD_AND_SAVE_NEW_TASK_SUCCESS';
export const ADD_AND_SAVE_NEW_TASK_FAIL = 'ADD_AND_SAVE_NEW_TASK_FAIL';
export const REMOVE_TASK_LOCALLY = 'REMOVE_TASK_LOCALLY';

export const FETCH_EXAMS = 'FETCH_EXAMS';
export const REFRESH_EXAMS = 'REFRESH_EXAMS';
export const FETCH_EXAMS_SUCCESS = 'FETCH_EXAMS_SUCCESS';
export const FETCH_EXAMS_FAIL = 'FETCH_EXAMS_FAIL';
export const REMOVE_EXAM_LOCALLY = 'REMOVE_EXAM_LOCALLY';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const REFRESH_EVENTS = 'REFRESH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';
export const REMOVE_EVENT_LOCALLY = 'REMOVE_EVENT_LOCALLY';

export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const SET_USER_PREFERENCE_FAIL = 'SET_USER_PREFERENCE_FAIL';
export const RESET_PERMISSION_PREFERENCES = 'RESET_PERMISSION_PREFERENCES';

export const FORCE_REFRESH = 'FORCE_REFRESH';

export const SET_SCROLL_CONTAINER_REF = 'SET_SCROLL_CONTAINER_REF';